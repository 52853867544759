export const colors = {
  blue: '#1c618c',
  green: '#a4d037',
  purple: '#ad26b4',
  yellow: '#fecd35',
  red: '#f05230',
  darkgrey: 'rgba(0, 0, 0, 0.87)',
  midgrey: '#738a94',
  lightgrey: '#c5d2d9',
  whitegrey: '#f9f9f9',
  pink: '#fa3a57',
  brown: '#a3821a',
  // darkmode: color(var(--darkgrey) l(+2%)),
  darkmode: '#191b1f',
  lightergrey: '#f8f8f8',
  grey: '#262637',
};
